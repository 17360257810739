import { Environment } from '@compass/environment';
import { AppConfig } from '../app-config';

export const appConfig: AppConfig = {
  environment: Environment.Production,
  minLogLevel: 'info',
  baseApiUrl: 'https://api.asmt.app/',
  appName: 'Participant',
  reportUrl: 'https://report.highmatch.com',
};
